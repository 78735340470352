/**
 * Use the structure:
 * colors: {
 *   category: {
 *     name: [lightColor, darkColor],
 *     name2: [lightColor, darkColor],
 *     name3: color, // used in both light and dark themes
 *   }
 * }
 */

module.exports = {
  // if true, this flag will activate dark mode automatically if the user has their OS in Dark mode
  enableDarkThemeFromOS: false,
  colors: {
    primary: {
      light   : '#FF5F6D',
      default : ['#EB0A1E'],
      dark    : '#C50F1F'
    },
    secondary: {
      default : ['#000'],
    },
    state: {
      success       : '#7DCB61',
      'success-dark': '#598F45',
      warn          : '#F9D067',
      'warn-dark'   : '#D6A832',
      danger        : '#DA2222',
      'danger-dark' : '#EB0A1E'
    },
    base: {
      text    : ['#222', '#f0f0f0'],
      link    : '#222',
      bg      : ['#fbfbfb', '#333'],
      lightest: '#fff',
      lighter : '#f9f9f9',
      light   : '#f0f0f0',
      default : '#000',
      dark    : '#999',
      darker  : '#777',
      darkest : '#000',
      black   : '#000',
      white   : '#FFF'
    },
    gray: {
      1       : '#F2F2F2',
      2       : '#EAEAEA',
      3       : '#D7D7D7',
      4       : '#A5A5A5',
      5       : '#7C7D80',
      6       : '#585958'
    }
  },
  matches: {
    headbar  : ['primary', 'base-text'],
    highlight: ['secondary', 'base-text'],
    success  : ['state-success', 'base-text'],
    danger   : ['state-danger', 'base-lightest'],
    warn     : ['state-warn', 'base-text'],
    info     : ['state-info', 'base-text'],
  }
};
