import React from 'react';

import AnchorLink from 'components/__atoms/anchor-link';
import Wrapper from 'components/__atoms/wrapper';
import Section from 'components/__atoms/section';
import footerData from './footer-data';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism Footer
 *
 * The portal footer
 */

function Footer(props) {
  const t = i18n.useTranslations('components.footer');
  const {
    footerMenuData = [],
    footerSecondaryMenuData = footerData?.footerSecondaryMenuData || [],
    spacing = '5',
    bgColor = 'blue-1'
  } = props;

  const appStoreLink = t('appStoreURL') || null;
  const googlePlayLink = t('googlePlayURL') || null;
  const instagramLink = t('instagramURL') || null;
  const facebookLink = t('facebookURL') || null;

  return (
    <Wrapper className={css['organisms__footer']}>
      <Wrapper className={css['footer']} data-testid="footer-wrapper" bgColor={bgColor} spacing={spacing}>
        <Section data-testid="footer-section" className={css['section-footer']}>
          <div className={css['footer-header']}>
            <img className={css['header-logo__image']} src="/images/logos/logo_kinto_white.svg" alt="Toyota" />
          </div>
          <div className={css['footer-images']}>
            <div className={css['google_app_text_div']}>
              <div className={css['google_app_div']}>
                {googlePlayLink && (
                  <a href={googlePlayLink} target="_blank">
                    <img className={css['google_play']} src="/images/google_play.svg" alt="Google Play" />
                  </a>
                )}
                {appStoreLink && (
                  <a href={appStoreLink} target="_blank">
                    <img className={css['app_store']} src="/images/app_store.svg" alt="App Store" />
                  </a>
                )}
              </div>
              <p className={css['footer-text']}>
                {t('text')}
                {t('cuit')}
              </p>
            </div>
            <div className={css['instagram_facebook_div']}>
              {instagramLink && (
                <a href={instagramLink} target="_blank">
                  <img className={css['instagram_facebook']} src="/images/icon_instagram.svg" alt="Instagram" />
                </a>
              )}
              {facebookLink && (
                <a href={t('facebookURL')} target="_blank">
                  <img className={css['instagram_facebook']} src="/images/icon_facebook.svg" alt="Facebook" />
                </a>
              )}
            </div>
          </div>
          {footerMenuData?.length > 0 && (
            <div className={css['footer-content']}>
              <div className={css['footer-content__content']}>
                (
                <ul className={css['footer-links']}>
                  {footerMenuData.map((item, index) => {
                    return (
                      <li key={index}>
                        <AnchorLink
                          color="white"
                          linkLabel={item?.anchorText}
                          isExternal={item?.isExternal}
                          size="small"
                          link={item?.url}
                          data-testid={`footer-link-${index}`}
                        />
                      </li>
                    );
                  })}
                </ul>
                )
              </div>
            </div>
          )}
        </Section>
      </Wrapper>
      {footerSecondaryMenuData?.length > 0 && (
        <Wrapper
          spacing="3"
          bgColor="blue-2"
          className={css['footer-secondary-wrapper']}
          data-testid="footer-secondary-wrapper">
          <Section tag="div" className={css['section-links-list']}>
            <ul className={css['links-list']}>
              {footerSecondaryMenuData.map((item, index) => {
                return (
                  <li key={index}>
                    <AnchorLink
                      color="white"
                      linkLabel={item?.anchorText}
                      size="small"
                      hasArrow={false}
                      isExternal={item?.isExternal}
                      link={item?.url}
                      data-testid={`footer-secondary-link-${index}`}
                    />
                  </li>
                );
              })}
            </ul>
          </Section>
        </Wrapper>
      )}
    </Wrapper>
  );
}

export default Footer;
