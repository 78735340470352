import getConfig from 'next/config';

export function createURL(value) {
  if (!value) {
    return false;
  }

  try {
    const url = new URL(value);
    return url;
  } catch (error) {
    return false;
  }
}

export function verifyIfLinkIsInAnotherDomain(link) {
  const componentURL = createURL(link);

  if (!componentURL) {
    return false;
  }

  const { publicRuntimeConfig } = getConfig();
  const serverURL = createURL(publicRuntimeConfig?.SERVER_ADDR);

  const linkHostName = componentURL.hostname;
  const serverHostName = serverURL.hostname;

  if (linkHostName !== serverHostName) {
    return true;
  }
}
