import { useIntl, useNow, useTranslations, useLocale, useTimeZone } from 'next-intl';
import i18nOptions from '__config/i18n';

function getSBLangController() {
  return {
    lang: {
      options: i18nOptions.locales,
      control: {
        type: 'select',
        onChange: (value) => {
          location.href = location.href.replace(/[?&]lang=[a-z\-A-Z]+/, '') + `&lang=${value}`;
        }
      },
      description: 'Changes the language and translation used in the page.'
    }
  };
}

const i18nUtils = {
  async getMessages(locale) {
    // TODO: SETUP: you may want to get messages from a server api
    return (
      await import(
        `utils/i18n/languages/${locale.replace(/[^a-z\-A-Z]/g, '').substring(0, 5)}.json`
      )
    ).default;
  },
  useIntl,
  useTranslations,
  useLocale,
  useTimeZone,
  useNow,
  getSBLangController,
  options: i18nOptions
};

export default i18nUtils;
