import React from 'react';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule SkipLinks
 * 
 * A skip links component for the layout
 */
function SkipLinks (props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.skip-links');

  const {
    className = "",
    children,
    ...other
  } = props;

  return <>
    <ul className={`${css["skip-links"]} ${className}`} {...other}>
      <li className={css['skip-links__item']}><a className={css['skip-links__anchor']} data-visuallyhidden href="#navigation">Ir para o menu do site</a></li>
      <li className={css['skip-links__item']}><a className={css['skip-links__anchor']} data-visuallyhidden href="#main">Ir para o conteúdo do site</a></li>
    </ul>
  </>;
}

export default SkipLinks;
