import React, { createContext, useContext } from 'react';
import Script from 'next/script';

import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const AnalyticsContext = createContext();

function AnalyticsProvider({ children }) {
  // whatever you set here as default, will be used as server side as well

  const gtagId = publicRuntimeConfig.GTM_TAG_CODE;

  const analyticsData = {};

  return (
    <AnalyticsContext.Provider value={analyticsData}>
      {children}
      <>
        <Script
          id="gtag-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            /* eslint-disable max-len */
            __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${gtagId}');
              `
            /* eslint-enable max-len */
          }}
        />

        <noscript>
          <iframe
            title="gtm-iframe"
            src={`https://www.googletagmanager.com/ns.html?id=${gtagId}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
      </>

      {/* // TODO: Add support for facebook and other tag managers/analytics here */}
    </AnalyticsContext.Provider>
  );
}

function useAnalytics() {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }

  return context;
}

export { AnalyticsProvider, AnalyticsContext, useAnalytics };
