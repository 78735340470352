const footerData = {
  footerSecondaryMenuData: [
    {
      anchorText: 'Términos y condiciones',
      url: 'https://media.toyota.com.ar/df0896a8-b7b0-4abd-bf52-d2395a8d93b4.pdf',
      isExternal: false,
    },
    {
      anchorText: 'Términos y condiciones del servicio',
      url: 'https://media.toyota.com.ar/fee132c9-08f1-4425-bf52-15046c35459f.pdf',
      isExternal: false,
    },
    {
      anchorText: 'Política de Privacidad',
      url: 'https://media.toyota.com.ar/28870dd3-a8b7-4b47-afd3-80653ac75b0c.pdf',
      isExternal: false,
    },
    {
      anchorText: 'Botón de arrepentimiento',
      url: 'https://www.toyota.com.ar/arrepentimiento',
      isExternal: false,
    },
    {
      anchorText: 'Defensa de las y los consumidores. Para reclamos ingrese aquí',
      url: 'https://autogestion.produccion.gob.ar/consumidores',
      isExternal: false,
    }
  ]
};

export default footerData;
