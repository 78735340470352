import React from 'react';
import { useRouter } from 'next/router';
import Transition from 'utils/hocs/transition';
import { enableDarkThemeFromOS } from '__config/styleguide/colors';
import css from './styles.module.scss';

import Footer from 'components/__organisms/footer';
import SkipLinks from 'components/__molecules/skip-links';

const Layout = ({ children }) => {
  const router = useRouter();
  const timestamp = new Date().toUTCString();

  return (
      <div className={css['layout']} data-generated-at={timestamp} data-theme={enableDarkThemeFromOS ? 'os' : 'light'}>
        <SkipLinks />
        <Transition location={router.pathname}>
          {!router.isFallback && children}
        </Transition>

        {!router.isFallback && <Footer locale={router?.locale?.toLowerCase()} />}
      </div>

  );
};

export default Layout;
