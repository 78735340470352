import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';
// import useStyles from '@on2-dev/iso-style-loader/src/useStyles';

/**
 * Atom IconDownload
 *
 * <!-- TODO: add a description here! -->
 */
function IconDownload (props) {
  // useStyles(css);

  const {
    className = "",
    color,
    size,
  } = props;

  return <svg data-color={color} data-size={size} className={`${css["icon-download-container"]} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.5 12.32v9.816h21V12.32m-10.562 3.976 4.722-3.976m-4.722 3.976L6.843 12.32m5.095 3.976V3"
    />
  </svg>;
}

export default IconDownload;
