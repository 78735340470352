import React from 'react';

// import useStyles from '@on2-dev/iso-style-loader/src/useStyles';

// loading the sass style fot the component
import css from './styles.module.scss';

function Section(props) {
  // useStyles(css);

  const {
    className = '',
    children,
    hasGrid = false,
    fullScreen = false,
    tag = false,
    ...other
  } = props;

  const Tag = tag ? tag : 'section';

  return (
    <>
      <Tag
        className={`${css['atom__section-container']} ${className}`}
        {...other}
        data-grid={hasGrid ? true : undefined}
        data-full-screen={fullScreen}
      >
        {children}
      </Tag>
    </>
  );
}

export default Section;
